import React from 'react';
import { Avatar, Dropdown, Menu, Space } from 'antd';
import * as Icons from '@ant-design/icons';

import { StyledHeader, StyledMenuFoldIcon, StyledMenuUnfoldIcon } from '../styled';
import { useHeader } from '../hooks';
import { StyledHeaderButton, StyledHeaderLeft, StyledHeaderRight, StyledPortalArea } from '../styled/header';
import { ParcelProcessor } from '../../parcels/containers/parcel';
import { CustomsPing } from '../../customs/containers/ping';
import { BasketProcessor } from '../../baskets/containers/basket-processor';

export const AppHeader = () => {
  const { toggleSidebar, sidebarIsOpen, avatarText, logout } = useHeader();

  return (
    <StyledHeader $wide={!sidebarIsOpen}>
      <StyledHeaderLeft>
        {sidebarIsOpen ? <StyledMenuFoldIcon onClick={toggleSidebar} /> : <StyledMenuUnfoldIcon onClick={toggleSidebar} />}
      </StyledHeaderLeft>
      <StyledPortalArea />
      <StyledHeaderRight>
        <Space size={8}>
          <BasketProcessor />
          <ParcelProcessor />
          <CustomsPing />
        </Space>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={logout} icon={<Icons.LogoutOutlined />}>
                Çıxış
              </Menu.Item>
            </Menu>
          }
        >
          <StyledHeaderButton>
            <Avatar>{avatarText}</Avatar>
          </StyledHeaderButton>
        </Dropdown>
      </StyledHeaderRight>
    </StyledHeader>
  );
};
